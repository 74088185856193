<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="content content-hasfooter" ref="projectlist" v-on:scroll="scrollKeeper($event)">
    <table class="table table-fix">
      <thead>
          <tr>
              <th>    
                  <a :class="ordercol=='Data._sortartist' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data._sortartist'}">Name</a>
              </th>
              <th>
                  <a :class="ordercol=='Data.Werktitel.Content[0]' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Werktitel.Content[0]'}" v-html="['Titel', 'Title'][$root.languageid]"></a>
              </th>
              <th>
                  <a :class="ordercol=='Data.Jahr.Content' ? ordermode : ''" v-on:click="orderedUsers = {order: 'Data.Jahr.Content'}" v-html="['Jahr', 'Year'][$root.languageid]"></a>
              </th>
          </tr>
      </thead>
      <tbody v-if="post">
        <router-link 
            tag="tr"
            v-bind:key="key" 
            :to="{ name: 'Work', params: { lang: $root.language[$root.languageid], type: 'Work', name: $root.u(row.Contribution.Name),  id: row.Contribution.Id }}"
            v-for="(row, key) in orderedUsers"
            v-if="row.Data.Werktitel.Content[$root.languageid]"
        >
                <td v-html="row.Data._artist"></td>
                <td v-html="row.Data.Werktitel.Content[$root.languageid]"></td>
                <td v-if="row.Data.JahrDarstellung.Content">{{row.Data.JahrDarstellung.Content}}</td>
                <td v-else>{{row.Data.Jahr.Content | yearformat}}</td>
        </router-link>  
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  components: {
  },
  data () {
    return {
      loading: false,
      post: null,
      error: null,
      ordercol: 'Data._sortartist',
      ordermode: 'asc'
    }
  },
  computed: {
    orderedUsers: {
        get: function () {
            return this.post ? _.orderBy(this.post.Documents, this.ordercol, this.ordermode) : null
        },
        set: function (value) {
            if (this.ordercol == value.order) {
                this.ordermode = this.ordermode == 'desc' ? 'asc' : 'desc'
            }
            else {
                this.ordercol = value.order
                this.ordermode = 'asc'
            }
        }
    }
  },
  created () {
    if (this.$route.name === 'Projects') {
        this.post = null
        this.fetchData()
    }
  },
  watch: {
    '$route' () {
        if (this.$route.name === 'Projects') {
          this.error = this.post = null
          this.fetchData()          
        }
    }
  },
  methods: {
    scrollKeeper(e) {
      this.$root.yPos.projectlist = e.target.scrollTop;
    },
    fetchData() {
      this.loading = true
      try {
        this.$store.dispatch('loadData', {
          mode: 'contributions', 
          issue: 1, 
          chapter: 2, 
          params: {
            data: 'Jahr|Künstler|Werktitel|Name|JahrDarstellung',
            references: 'false',
            sort: '5'
          }
        }).then((data) => {
          data.Documents.forEach(element => {
            element.Data.Jahr.Content = 1*element.Data.Jahr.Content;
            try {
              element.Data._artist = "";
              element.Data._artist = element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[this.$root.languageid == 2 ? 3 : 1]
              if (element.Data._artist != "")
                element.Data._artist += " "
              element.Data._artist += element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[this.$root.languageid == 2 ? 2 : 0]
              element.Data._sortartist = element.Data.Künstler.Reference[element.Data.Künstler.Content].Name.Parsed[this.$root.languageid == 2 ? 2 : 0];
              
            } catch (error) {
              element.Data._artist = '<font color="#F00">Reference Missing!</font>'
            }
          });
          this.post = data
          this.loading = false;
          this.$nextTick(() => {
            try {
              this.$refs.projectlist.scrollTop = this.$root.yPos.projectlist;
            } catch (err) {
              console.warn(err)
            }
          });
          this.$root.addBreadcrumb(this.$route.meta.translation);
          this.$root.applyMeta(`${this.$route.meta.translation[this.$root.languageid]} - ${this.$root.metatags.title[this.$root.languageid]}`)

        });  
      } catch (error) {
        this.error = error;
      }      
      
    }
  }
}
</script>


<style lang="scss">

  @import "../main";

  /* Table Div */
.table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  &-fix {
    & > tbody > tr,
    & > thead > tr {
      & > td:nth-child(1), 
      & > th:nth-child(1) { 
        width: 40%; 
      }
      & > td:nth-child(2), 
      & > th:nth-child(2) { 
        width: 40%; 
        padding: $margin-standard / 2;
        font-style: italic;
      }
      & > td:nth-child(3), 
      & > th:nth-child(3) { 
        width: 20%; 
        text-align: right;
        @include sm() {
          display: none;
        }
      }      
    }
    & > thead {
      border-top: $standard-border;
    }
  }

  td, th {
    font-weight: normal;
    text-align: left;
    vertical-align: top;
    padding: $margin-standard / 2 0;
    a {
      margin-left: 1em;
      &:before {
        width: $icon-size / 2;
        height: $icon-size / 2;
        border-top: 2px solid black;
        content: " ";
        position: absolute;
        margin-left: -1em;
        margin-top: 0.6em;
    }      
      &.asc,
      &.desc {
        &:before {
          border-left: 2px solid black;
        }
      }
      
      &.desc {
        &:before {
          transform: rotate(-135deg);
          margin-top: 0.2em;
        }
      }
      &.asc {
        &:before {
          transform: rotate(45deg);
          margin-top: 0.5em;
        }
      }
    }

  }
  & tr {
    height: ($header-size - ($margin-standard / 2)) / 2;
    border-bottom: $standard-border;
    cursor: pointer;
  }

}

</style>